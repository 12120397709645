import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { Link, type LinkProps } from "react-aria-components";
import { useIntercom } from "react-use-intercom";
import { WButton } from "../../components/button/WButton.tsx";
import { chatConfigAtom, useConfig } from "../../config.ts";
import { showWarn } from "../../dev/errorStream.ts";

export interface SupportLinkProps {
    children: ReactNode;
    isButton?: boolean;
    color?: "default" | "primary" | "success" | "error" | "muted";
}

function IntercomLink(props: SupportLinkProps) {
    const { show } = useIntercom();

    return props.isButton ? <WButton action={show} {...props} /> : <Link onPress={show} {...props} />;
}

function openQiscusChat() {
    const btn = document.querySelector<HTMLButtonElement>(".qcw-trigger-btn");
    btn?.click();
}

function openOncehubChat() {
    const btn = document.querySelector<HTMLButtonElement>("#oncechat-link");
    btn?.click();
}

export function SupportLink(props: SupportLinkProps) {
    const chatConfig = useAtomValue(chatConfigAtom);
    const { isButton, color = "primary", ...forwardProps } = props;
    const { supportEmail } = useConfig();

    let onPress: (() => void) | undefined = undefined;

    if (chatConfig) {
        const { type } = chatConfig;

        switch (type) {
            case "intercom": {
                return <IntercomLink {...props} />;
            }
            case "qiscus": {
                onPress = openQiscusChat;
                break;
            }
            case "botika": {
                break;
            }
            case "oncehub": {
                onPress = openOncehubChat;
                break;
            }
            default: {
                showWarn("Unsupported chat provider type", type satisfies never);
                break;
            }
        }
    }

    return isButton ? (
        <WButton color={color} action={onPress ?? `mailto:${supportEmail}`} {...forwardProps} />
    ) : onPress ? (
        <Link onPress={onPress} {...forwardProps} />
    ) : (
        <SupportEmail {...forwardProps} />
    );
}

export function SupportEmail({ children, ...props }: LinkProps) {
    const { supportEmail } = useConfig();
    children ??= supportEmail;
    return (
        <Link href={`mailto:${supportEmail}`} {...props}>
            {children}
        </Link>
    );
}
