import type { ManagedServiceEnum, ServiceImage } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { useAtomValue } from "jotai/react";
import { first, last } from "remeda";
import { siteKubernetesAtom } from "../../../config.ts";
import type { SelectedOs } from "../../compute/os/os.ts";
import type { ManagedServiceLoc } from "../servicesQuery.ts";

export interface ServiceOs extends SelectedOs {
    os_name: ManagedServiceEnum;
    os_version: string;

    /** Prevent extra fields and force usage of {@link extractServiceOs} */
    service?: never;
}

export function extractServiceOs(obj: ManagedServiceLoc): ServiceOs {
    return { os_name: obj.service, os_version: obj.version };
}

export type ServiceImageType = "managed_service" | "kubernetes";

/* TODO: admin can see not published versions */
export function getAvailableImages(images: ServiceImage[]): ServiceImage[] {
    return images;
}

export function GetEnabledImages(images: ServiceImage[]): ServiceImage[] {
    const kubernetesEnabled = useAtomValue(siteKubernetesAtom);

    return kubernetesEnabled
        ? [...images.values()]
        : [...images.values()].filter((item: ServiceImage) => item.service_name !== "kubernetes");
}

export function getDefaultOs(images: ServiceImage[]): ServiceOs {
    const defaultImage = notNull(first(images), "No default image found");
    return {
        os_name: defaultImage.service_name,
        os_version: getDefaultVersion(defaultImage),
    };
}

export function getDefaultVersion(image: ServiceImage) {
    return notNull(last(image.versions), "No default version found");
}
