import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { atom } from "jotai/vanilla";
import type { ReactNode } from "react";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { billingStateAtom } from "./billingLogic.tsx";
import { referralCodeAtom } from "./referral/referralLogic.ts";

export interface NoticeItem {
    id: string;
    content: ReactNode;
}

export const firstAccountNoticesAtom = atom((get) => {
    const { data: billingState } = get(billingStateAtom);
    // Default to not showing when data has not loaded yet
    if (!billingState) {
        return [];
    }

    const { firstAccountCredit, canEnterReferralCode } = billingState;

    const referralCode = get(referralCodeAtom);
    // NB: Technically, campaign codes can be entered even when referral codes are not allowed, but default to not
    // showing since we don't differentiate code types here.
    const canUseReferralCode = canEnterReferralCode && !!referralCode;

    return filterFalse<NoticeItem>([
        !!firstAccountCredit && {
            id: "firstAccountCredit",
            content: (
                <>
                    With first billing account you will be able to unlock your complimentary free credit of{" "}
                    <b>
                        <CurrencyPromo value={firstAccountCredit} />
                    </b>
                    .
                </>
            ),
        },
        canUseReferralCode && {
            id: "referralCode",
            content: (
                <>
                    You have an active campaign code (<b>{referralCode}</b>) pending to get additional bonus!
                </>
            ),
        },
    ]);
});
