import { findById } from "@warrenio/utils/collections/findById";
import { ActionMenu } from "../ActionMenu.tsx";
import { Spacer } from "../Spacer.tsx";
import { WButton } from "../button/WButton.tsx";
import type { SortFieldInfo, SortState } from "../data/getSortedItems.ts";
import { MaskIcon } from "../icon/MaskIcon.tsx";

export interface SortMenuProps extends SortState {
    sortFields: SortFieldInfo[];

    onSortId: (id: string) => void;
    onSortAsc: (asc: boolean) => void;
}

export function SortMenu({ sortFields, sortId, onSortId, sortAsc = true, onSortAsc }: SortMenuProps) {
    const currentSort = findById(sortFields, sortId);
    const currentSortName = currentSort?.title;

    return (
        <>
            <WButton
                ariaLabel="Sort direction"
                color="muted"
                variant="ghost"
                size="xs"
                fontSize="font-size-small"
                icon={sortAsc ? "jp-icon-caretdown" : "jp-icon-caretup"}
                iconSide="right"
                action={() => onSortAsc?.(!sortAsc)}
            >
                {currentSortName}
            </WButton>
            <Spacer />
            <ActionMenu header="Sort by" onAction={({ id }) => onSortId(id)} selectedKeys={[sortId]} items={sortFields}>
                <WButton color="muted" variant="ghost" size="xs" ariaLabel="Sort by" action={undefined}>
                    <MaskIcon className="jp-icon-toggles size-0.75rem" />
                </WButton>
            </ActionMenu>
        </>
    );
}
