import { queryOptions, type QueryClient, type QueryKey } from "@tanstack/react-query";
import type {
    AutomatedTopUpEnableDisableBody,
    BillingAccount,
    BillingAccountCreateFields,
    BillingAccountUpdateFields,
    ModifyLowBalanceNoticeSettingsBody,
    SaveMethodsBody,
} from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mapOnValues } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { configAtom } from "../../config.ts";
import { jsonEncodedBody, urlEncodedBody } from "../../utils/fetchClient.ts";
import { queryThen } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { getResourceById } from "../api/resourceTypeException.ts";
import { getResourceIconClass } from "../api/resourceTypes.tsx";
import { raiseRequestToast, type RequestToastOptions } from "../notifications/toast.tsx";
import { EBillingAccount } from "./billingLogic.tsx";

export interface BillingAccountWithType extends BillingAccount {
    $type: "billing_account";
}

export type Response = Map<BillingAccount["id"], BillingAccountWithType>;

export const queryKey: QueryKey = ["billing_account_list"];

function billingAccountFromData(data: BillingAccount): BillingAccountWithType {
    return { ...data, $type: "billing_account" };
}

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> =>
            mapFromEntries(
                getResponseData(await client.GET("/payment/billing_account/list", { signal })),
                (account) => [account.id, billingAccountFromData(account)],
            ),
    });
}

export const rawBillingAccountQueryAtom = atomFromStandardQueryOptions(getQuery);

/** All the billing accounts of the user, wrapped in {@link EBillingAccount} */
export const eBillingAccountQueryAtom = atom((get) => {
    const config = get(configAtom);
    return queryThen(get(rawBillingAccountQueryAtom), (data) =>
        mapOnValues(data, (account) => new EBillingAccount(account, config)),
    );
});

export function useBillingAccount(baId: BillingAccount["id"]) {
    const data = useSuspenseQueryAtom(eBillingAccountQueryAtom);
    return getResourceById(data, baId, "billing_account");
}

//#region Mutations

const toastOptions: Partial<RequestToastOptions> = { icon: getResourceIconClass("billing_account") };

export function createBillingAccountMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["billing_account/create"],
        async mutationFn({ body }: { body: BillingAccountCreateFields }) {
            return billingAccountFromData(
                getResponseData(await api.POST("/payment/billing_account", { ...urlEncodedBody, body })),
            );
        },
        async onSettled(_res, err) {
            // TODO: link to billing account?
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Billing account created",
                error: "Error creating Billing account",
                successType: "success",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function updateBillingAccountMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["billing_account/update"],
        async mutationFn({ body, id }: { body: BillingAccountUpdateFields; id: BillingAccount["id"] }) {
            return billingAccountFromData(
                getResponseData(
                    await api.PUT("/payment/billing_account", {
                        ...urlEncodedBody,
                        params: { query: { billing_account_id: id } },
                        body,
                    }),
                ),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Billing account updated",
                error: "Error updating Billing account",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function deleteBillingAccountMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["billing_account/delete"],
        async mutationFn({ id }: { id: BillingAccount["id"] }) {
            return getResponseData(
                await api.DELETE("/payment/billing_account", { params: { query: { billing_account_id: id } } }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Billing account deleted",
                error: "Error deleting Billing account",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function setDefaultBillingAccountMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["billing_account/set_default"],
        async mutationFn(id: BillingAccount["id"]) {
            return getResponseData(
                await api.POST("/payment/billing_account/set_default", {
                    params: { query: { billing_account_id: id } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Billing account set as default",
                error: "Error setting billing account as default",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function activateBillingAccountCampaignMutation(api: ApiClient) {
    return mutationOptions({
        mutationKey: ["billing_account/activate_campaign"],
        async mutationFn({ id, campaign_code }: { id: BillingAccount["id"]; campaign_code: string }) {
            return getResponseData(
                await api.POST("/payment/billing_account/{id}/campaign_activate/{campaign_code}", {
                    params: { path: { id, campaign_code } },
                }),
            );
        },
        onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Campaign activated",
                error: "Error activating campaign",
            });
        },
    });
}

export function enableDisableAutomatedTopUpMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["billing_account/recurring_payment"],
        async mutationFn({ id, body }: { id: BillingAccount["id"]; body: AutomatedTopUpEnableDisableBody }) {
            return getResponseData(
                await api.PUT("/payment/billing_account/{id}/recurring_payment", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { id } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Automated top up saved",
                error: "Error enabling/disabling automated top up",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function modifyLowBalanceNoticeSettingsMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["billing_account/low_balance_notice_settings"],
        async mutationFn({ body }: { body: ModifyLowBalanceNoticeSettingsBody }) {
            return getResponseData(
                await api.PUT("/payment/low_balance_notice_settings", {
                    ...jsonEncodedBody,
                    body,
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Low balance notification settings saved",
                error: "Error modifying low balance notification settings",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function saveBillingMethodsMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["billing_account/save_methods"],
        async mutationFn(body: SaveMethodsBody) {
            return getResponseData(await api.POST("/payment/link/save_methods", { ...jsonEncodedBody, body }));
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Billing methods saved",
                error: "Error saving Billing methods",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

//#endregion
