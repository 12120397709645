import C from "./LeftRight.module.css";

import type { ReactNode } from "react";

export interface LeftRightProps {
    icon?: ReactNode;
    title?: ReactNode;
    right?: ReactNode;

    /** Custom left side */
    children?: ReactNode;
}

export function LeftRight({ icon, title, right, children }: LeftRightProps) {
    return (
        <>
            <span className={C.left}>
                {children ? (
                    children
                ) : (
                    <>
                        {!!icon && <span className={C.icon}>{icon}</span>}
                        {!!title && <span className={C.title}>{title}</span>}
                    </>
                )}
            </span>

            {!!right && <span className={C.right}>{right}</span>}
        </>
    );
}
