import { findById } from "@warrenio/utils/collections/findById";
import { sort, sortBy } from "remeda";
import type { SortableValue } from "./comparers.ts";

export interface SortFieldInfo {
    id: string;
    title: string;
}

export type SimpleSortField<T> = SortFieldInfo &
    (
        | {
              getValue: (item: T) => SortableValue;
              compare?: never;
          }
        | {
              getValue?: never;
              compare: (a: T, b: T) => number;
          }
    );

export interface SortState {
    sortId: string;
    sortAsc: boolean;
}

export function getSortedItems<TItem>(
    sortFields: SimpleSortField<TItem>[],
    { sortId, sortAsc }: SortState,
    items: TItem[],
) {
    const { getValue, compare } = findById(sortFields, sortId, sortFields[0]);
    if (compare) {
        return sort(items, sortAsc ? compare : (a, b) => -compare(a, b));
    } else {
        return sortBy(items, [(i) => getValue(i), sortAsc ? "asc" : "desc"]);
    }
}
