import C from "./EmptyItem.module.css";

import type { ReactNode } from "react";
import { MaskIcon } from "../icon/MaskIcon";

export function EmptyItem({ children, icon }: { children: ReactNode; icon?: string }) {
    return (
        <div className={C.EmptyItem}>
            {icon ? (
                <div className={C.EmptyItemIcon}>
                    <MaskIcon className={`${icon} size-5rem`} />
                </div>
            ) : null}
            {children}
        </div>
    );
}

export function NoResults() {
    return <EmptyItem icon="jp-cactus-icon">No results found</EmptyItem>;
}
