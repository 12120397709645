import { useEffect, useState, type Dispatch, type SetStateAction } from "react";
import type { SortFieldInfo } from "../data/getSortedItems.ts";
import type { SidebarListId } from "./SidebarList.tsx";

export interface SidebarListSortStoreItem {
    fieldId: string;
    asc: boolean;
}
export type SidebarListSortStore = {
    [key in SidebarListId]?: SidebarListSortStoreItem;
};

const LOCALSTORAGE_KEY = "listSortData";

const getSavedSortProperty = <P extends keyof SidebarListSortStoreItem, V extends SidebarListSortStoreItem[P]>(
    listId: SidebarListId,
    propertyName: P,
    defaultValue: V,
): V => {
    let result: V;
    try {
        const sortIdStorageObj = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY)!) as SidebarListSortStore;
        result = (sortIdStorageObj[listId]?.[propertyName] ?? defaultValue) as V;
    } catch (e) {
        result = defaultValue;
    }
    return result;
};

export function useStoredSort(
    listId: SidebarListId,
    sortFields: SortFieldInfo[],
): {
    sortId: string;
    setSortId: Dispatch<SetStateAction<string>>;
    sortAsc: boolean;
    setSortAsc: Dispatch<SetStateAction<boolean>>;
} {
    const [sortId, setSortId] = useState<string>(() => {
        return getSavedSortProperty(listId, "fieldId", sortFields[0].id);
    });
    const [sortAsc, setSortAsc] = useState<boolean>(() => {
        return getSavedSortProperty(listId, "asc", true);
    });

    useEffect(() => {
        let sortStorageObj: SidebarListSortStore;
        try {
            sortStorageObj = (JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY)!) ?? {}) as SidebarListSortStore;
        } catch (e) {
            sortStorageObj = {};
        }
        sortStorageObj[listId] = { fieldId: sortId, asc: sortAsc };
        window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(sortStorageObj));
    }, [sortId, sortAsc, listId]);

    return { sortId, setSortId, sortAsc, setSortAsc };
}
