import C from "../../components/sidebar/ErrorItem.module.css";

import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import type { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ApiZodValidationError } from "../../modules/api/ApiError.ts";
import { ZodErrorMessage } from "../../modules/main/ErrorFallback.tsx";
import { errorToToastMessage } from "../../modules/notifications/toast.tsx";
import { cn } from "../../utils/classNames.ts";
import type { QStatus } from "../../utils/query/mergeQueries.ts";
import { getStatusErrors } from "../../utils/query/queryErrors.ts";
import { WTooltipButton } from "../button/WToolButton.tsx";
import { MaskIcon } from "../icon/MaskIcon.tsx";

export function ErrorMessage({ error }: { error: unknown }): ReactNode {
    if (import.meta.env.DEV && error instanceof ApiZodValidationError) {
        return <ZodErrorMessage error={error} />;
    }
    if (error instanceof Error) {
        return errorToToastMessage(error);
    }
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
}

interface ErrorBlockProps {
    error: unknown;
    resetErrorBoundary?: () => void;
}

export function ErrorDisplayBlock({ error, resetErrorBoundary }: ErrorBlockProps) {
    const iconElement = <MaskIcon className={cn("jp-wrong-icon", "size-1rem")} />; // icon && <MaskIcon className={cn(icon, "size-1rem")} />;

    return (
        <div className={C.ErrorBlockLink}>
            <div className={cn(C.ErrorBlock, "Toastify__toast--error")}>
                <div className="Toastify__toast-icon">{iconElement}</div>
                <div className={C.title}>
                    <b>An error happened:</b>
                    <br />
                    <ErrorMessage error={error} />
                </div>
                {resetErrorBoundary && (
                    <WTooltipButton
                        title="Reload"
                        size="xs"
                        variant="ghost"
                        icon="jp-icon-refresh"
                        action={resetErrorBoundary}
                    />
                )}
            </div>
        </div>
    );
}

export function ListErrorBoundary({ children }: { children: ReactNode }) {
    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary: _ }: ErrorBlockProps) => (
                // NB: `resetErrorBoundary` does not generally work because errors are cached so do not enable it.
                // TODO: Enable it when the `error` is a re-fetchable query error.
                <ErrorDisplayBlock error={error} resetErrorBoundary={undefined} />
            )}
        >
            {children}
        </ErrorBoundary>
    );
}

export function QueryErrorBlocks({ query }: { query: QStatus }) {
    const errors = getStatusErrors(query);
    return errors.map((e) => (
        <ErrorDisplayBlock key={e.id} error={e.error} resetErrorBoundary={() => discardPromise(e.refetch())} />
    ));
}
