import { getResourceIconClass, type ResourceType } from "../../modules/api/resourceTypes.tsx";
import { OsImageIcon } from "../../modules/compute/os/OsImageIcon.tsx";
import type { VirtualMachineLoc } from "../../modules/compute/vmQuery.ts";
import { MaskIcon } from "./MaskIcon.tsx";

export interface ResourceIconProps {
    item: VirtualMachineLoc | { $type: Exclude<ResourceType, "virtual_machine"> };

    className?: string;
    size?: string;
    color?: string;
}

export function ResourceIcon({ className, item, size = "size-1.125rem", color = "text-primary" }: ResourceIconProps) {
    const commonCn = `${size} ${color} ${className}`;

    switch (item.$type) {
        case "virtual_machine":
            return <OsImageIcon os_name={item.os_name} className={commonCn} />;
        default:
            return <MaskIcon className={`${commonCn} ${getResourceIconClass(item.$type)}`} />;
    }
}
