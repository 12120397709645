import C from "../../components/sidebar/StandardSidebar.module.css";

import { datetimeToDate } from "@warrenio/api-spec/conversion";
import type { ReactNode } from "react";
import { SiteName } from "../../components/SiteName.tsx";
import { localeCompareBy } from "../../components/data/comparers.ts";
import { getFilteredItems } from "../../components/data/getFilteredItems.ts";
import { getSortedItems, type SimpleSortField } from "../../components/data/getSortedItems.ts";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { ShortDate } from "../../components/l10n/DateFormat.tsx";
import { EmptyItem, NoResults } from "../../components/sidebar/EmptyItem.tsx";
import { SidebarList, type SidebarListState } from "../../components/sidebar/SidebarList.tsx";
import { StandardBlock } from "../../components/sidebar/StandardSidebar.tsx";
import { cn } from "../../utils/classNames.ts";
import { accessCreateLink } from "../api/resourceTypeLinks.ts";
import { useAccessList } from "./accessListQuery.ts";
import type { AccessDelegationWithType } from "./delegation/apiOperations.ts";
import { delegationLink, tokenLink } from "./links.ts";
import { scopeLabel } from "./scopeLabel.tsx";
import type { ApiTokenWithType } from "./token/apiOperations.ts";

type AccessItemWithType = ApiTokenWithType | AccessDelegationWithType;

const sortFields = [
    {
        id: "name",
        title: "Name",
        compare: localeCompareBy((i) => (i.$type === "api_token" ? i.description : i.name)),
    },
    { id: "created_at", title: "Created Date", getValue: (i) => datetimeToDate(i.created_at).getTime() },
    { id: "$type", title: "Type", getValue: (i) => i.$type },
] satisfies SimpleSortField<AccessItemWithType>[];

export function AccessList() {
    return (
        <SidebarList
            data={(props) => <AccessData {...props} />}
            title="Access"
            sortFields={sortFields}
            actionButton={{
                title: "Create New Access",
                action: accessCreateLink,
            }}
            listId="access"
        />
    );
}

export default AccessList;

function getSearchStrings(item: AccessItemWithType): string[] {
    switch (item.$type) {
        case "api_token":
            return [item.description];
        case "access_delegation":
            return [item.name, ...item.impersonations.map((i) => i.grantee_username)];
    }
}

function AccessData({ queryString, sortId, sortAsc }: SidebarListState): ReactNode {
    const data = useAccessList();

    let accessItems = getFilteredItems(queryString, data, getSearchStrings);
    accessItems = getSortedItems(sortFields, { sortId, sortAsc }, accessItems);

    const accessList = accessItems.map((i) =>
        i.$type === "api_token" ? <TokenBlock key={i.kong_id} item={i} /> : <DelegationBlock key={i.id} item={i} />,
    );

    const empty = (
        <EmptyItem icon="jp-access-icon">
            Create a new access to enable other users or systems to perform actions with this <SiteName /> account.
        </EmptyItem>
    );

    return accessList.length ? accessList : queryString ? <NoResults /> : empty;
}

export function TokenBlock({ item }: { item: ApiTokenWithType }) {
    const { created_at, description, restricted } = item;
    return (
        <StandardBlock link={tokenLink(item)}>
            <div className={C.StandardBlockIcon}>
                <MaskIcon className="jp-swap-icon size-0.875rem" />
            </div>

            <div className={cn(C.StandardBlockName, "text-ellipsis")}>
                <b className="text-ellipsis">{description}</b>
                <div className="text-ellipsis text-primary">{scopeLabel(restricted)}</div>
            </div>

            <div className={C.StandardBlockType}>
                <div className="text-success">API</div>
            </div>

            <div className={C.StandardBlockInfo}>
                <div className="text-muted">Created</div>
                <ShortDate date={created_at} />
            </div>
        </StandardBlock>
    );
}

export function DelegationBlock({ item }: { item: AccessDelegationWithType }) {
    const { created_at, restricted, name } = item;
    return (
        <StandardBlock link={delegationLink(item)}>
            <div className={C.StandardBlockIcon}>
                <MaskIcon className="jp-user-group-icon size-0.875rem" />
            </div>

            <div className={cn(C.StandardBlockName, "text-ellipsis")}>
                <b className="text-ellipsis">{name}</b>
                <div className="text-ellipsis text-primary">{scopeLabel(restricted)}</div>
            </div>

            <div className={C.StandardBlockType}>
                <div className="text-success">UI</div>
            </div>

            <div className={C.StandardBlockInfo}>
                <div className="text-muted">Created</div>
                <ShortDate date={created_at} />
            </div>
        </StandardBlock>
    );
}
