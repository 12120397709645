import C from "./ImpersonateTopItem.module.css";

import { useAtomValue, useSetAtom } from "jotai/react";
import { WButton } from "../../components/button/WButton.tsx";
import { impersonationAtom } from "./impersonation.ts";
import { stopImpersonatingAction } from "./stopImpersonatingAction.ts";

export function ImpersonateTopItem() {
    const impersonation = useAtomValue(impersonationAtom);
    const stopImpersonating = useSetAtom(stopImpersonatingAction);

    return (
        !!impersonation && (
            <div className={C.Holder}>
                <span>
                    Acting as <b>{impersonation.access_owner}</b>
                </span>
                <WButton className={C.Button} color="success" variant="basic" size="xs" action={stopImpersonating}>
                    Back to your account
                </WButton>
            </div>
        )
    );
}

export default ImpersonateTopItem;
