export type SortableValue = string | number | boolean;

// TODO: Set appropriate locale for sorting
const localesOptions: Parameters<typeof String.prototype.localeCompare>[1] = undefined;

const defaultCompareOptions: Intl.CollatorOptions = {
    usage: "sort",
    sensitivity: "base",
};

export function localeCompareBy<T>(getValue: (item: T) => string, options = defaultCompareOptions) {
    return (a: T, b: T) => getValue(a).localeCompare(getValue(b), localesOptions, options);
}

function splitAddress(address: string): number[] {
    return address.split(/[./]/).map((i) => parseInt(i, 10));
}

export function lexicographicCompare<T extends SortableValue>(a: T[], b: T[]): number {
    for (let i = 0; i < Math.min(a.length, b.length); i++) {
        if (a[i] < b[i]) return -1;
        if (a[i] > b[i]) return 1;
    }
    return a.length - b.length;
}

export function addressCompareBy<T>(getValue: (item: T) => string) {
    return (a: T, b: T) => {
        const aVal = splitAddress(getValue(a));
        const bVal = splitAddress(getValue(b));
        return lexicographicCompare(aVal, bVal);
    };
}
