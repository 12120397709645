import escapeStringRegexp from "escape-string-regexp";

export type SearchStrings = (string | false | null | undefined)[];

export function getFilteredItems<TItem>(
    queryString: string,
    items: TItem[],
    searchStringExtractor: (i: TItem) => SearchStrings,
) {
    const nQuery = queryString.trim();
    if (!nQuery) {
        return items;
    }

    const searchRegex = new RegExp(escapeStringRegexp(nQuery), "i");
    function doesSearchMatch(searchStrings: SearchStrings) {
        return searchStrings.some((s) => s && searchRegex.test(s));
    }

    return items.filter((i) => doesSearchMatch(searchStringExtractor(i)));
}
