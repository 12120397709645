import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import type { ReactElement } from "react";
import { mcn } from "../../utils/baseProps.ts";
import { getBrandText, getCardIconClass, type EBillingAccount } from "./billingLogic.tsx";

function getIconClass(account: EBillingAccount) {
    if (!account.isOpen) {
        return "jp-wrong-icon mask-icon text-error";
    }

    const { payType } = account;
    switch (payType) {
        case "card":
            return getCardIconClass(account.activeCard);
        case "invoice":
            return "jp-icon-text-editor mask-icon text-primary";
        case "prepay":
            // TODO: Appropriate icon?
            return "jp-wallet-icon mask-icon text-primary";
        default:
            exhaustiveSwitchCheck(payType);
    }
}

export function BillingAccountIcon({ account, ...props }: { account: EBillingAccount }) {
    return <span {...mcn(`size-1.125rem inline-block ${getIconClass(account)}`, props)} />;
}

function PayTypeInfo({ account }: { account: EBillingAccount }): ReactElement {
    const { payType } = account;
    switch (payType) {
        case "card": {
            const { activeCard } = account;
            if (!activeCard) {
                return <span className="text-error">No active card</span>;
            }
            const { last4, expire_month, expire_year } = activeCard;
            return (
                <span>
                    <span>{last4 ? `*${last4}` : getBrandText(activeCard)}</span>
                    <span>{`, ${expire_month}/${expire_year}`}</span>
                </span>
            );
        }
        case "invoice":
            return <span>Paying By Invoice</span>;

        case "prepay":
            return <span>Pre-payment</span>;

        default:
            exhaustiveSwitchCheck(payType);
    }
}

export function ShortInfoSelect({ account }: { account: EBillingAccount }) {
    if (!account.isOpen) {
        return <span className="text-error">Inactive</span>;
    }

    return <PayTypeInfo account={account} />;
}

export function ShortInfoList({ account }: { account: EBillingAccount }) {
    const { callToAction } = account;
    switch (callToAction) {
        case "add_payment_method":
            return <span className="text-error">Add payment method</span>;
        case "must_top_up":
            return <span className="text-error">Top up your account</span>;
        case "contact_support":
            return <span className="text-error">Contact support</span>;
        case null:
            return <PayTypeInfo account={account} />;
        default:
            exhaustiveSwitchCheck(callToAction);
    }
}
