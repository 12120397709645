import type { ServiceImage } from "@warrenio/api-spec/spec.oats.gen";
import { DataIcon } from "../../components/icon/DataIcon.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { mcn, type BaseProps } from "../../utils/baseProps";

export function ServiceImageIcon({
    image,
    isSelected,
    ...props
}: { image: ServiceImage | undefined; isSelected?: boolean } & BaseProps) {
    const { icon } = image ?? {};

    return icon ? (
        <DataIcon data={icon} mode={!isSelected ? "background" : "mask"} {...props} />
    ) : (
        <MaskIcon {...mcn("jp-icon-appcatalog", props)} />
    );
}
