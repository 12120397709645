import { useAtomValue } from "jotai/react";
import type { BaseProps } from "../../../utils/baseProps.ts";
import { VmImageIcon } from "../VmImageIcon.tsx";
import { imagesByOsNameAtom } from "../vmImagesQuery.ts";

export function OsImageIcon({ os_name, ...props }: { os_name: string } & BaseProps) {
    const imagesByOsName = useAtomValue(imagesByOsNameAtom);
    const image = imagesByOsName?.data?.get(os_name);
    return <VmImageIcon image={image} {...props} />;
}
